<template>
    <header id="header">
        <slot v-bind:scope-events="scopeEvents"></slot>
    </header>
</template>

<script>
import '@docsearch/css';

import docsearch from '@docsearch/js';

export default {
    name: 'SiteHeader',
    props: ['docSearchFilter'],
    data() {
        var self = this;
        return {
            scopeEvents: {
               openSidebar() {
                    console.log(self.$)
                    if (self.$) {
                        self.$('#sidebar-open').addClass('hidden')
                        self.$('#sidebar').removeClass('hidden')
                        self.$('#sidebar-close').removeClass('hidden')
                        self.$('#content-wrapper').addClass('overflow-hidden max-h-screen fixed')
                    }
                },
                closeSidebar() {
                    if (self.$) {
                        self.$('#sidebar').addClass('hidden')
                        self.$('#sidebar-close').addClass('hidden')
                        self.$('#sidebar-open').removeClass('hidden')
                        self.$('#content-wrapper').removeClass('overflow-hidden max-h-screen fixed')
                    }
                }
            }
        }
    },
    mounted() {
        var self = this;

        docsearch({
            apiKey: 'db5ebed711940d8edf223ea9384433b4',
            indexName: 'vendr',
            appId: '0CNLL1WO17',
            container: '#docsearch',
            placeholder: "Search the docs",
            searchParameters: {
                filters: self.docSearchFilter,
            },
            debug: false // Set debug to true if you want to inspect the dropdown
        })
    }
}
</script>