<template>
    <table class="changelog">
        <tbody>
            <slot />
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'Changelog'
}
</script>