import Vue from 'vue'
import VueScrollactive from 'vue-scrollactive'
import PortalVue from 'portal-vue'
import AnchorJS from 'anchor-js';

// Initialize anchors asap
window.anchors = new AnchorJS();
anchors.add('.markdown h1, .markdown h2, .markdown h3, .markdown h4, .markdown h5');

// Globally register all components by default as we won't know if / when a component
// will be used as it'll be decided by the CMS. If you don't want it globally registerd
// then prefix the filename with a '_'
// taken from https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
const requireComponent = require.context('./components', true, /(?:^|\/)(?!_)\w+\.vue$/);
// For each matching file name...
requireComponent.keys().forEach(fileName => {
    // Get the component config
    const componentConfig = requireComponent(fileName);
    // Get the default component from the config
    const component = componentConfig.default || componentConfig;
    // Globally register the component using the component name from the component config
    Vue.component(component.name, component)
})

// 3rd party global components
Vue.use(VueScrollactive)
Vue.use(PortalVue)

window.vm = new Vue({
    el: '#page'
});