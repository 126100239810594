<template>
    <scrollactive active-class="otp-active" :offset="-10" >
        <div class="mb-8  border-l border-gray-300 pl-6 overflow-x-hidden" v-if="toc.length > 0 && toc[0].depth !== 3">
            <h5 class="text-brand-blue-light uppercase tracking-wide font-black text-sm lg:text-xs">{{title}}</h5> 
            <ul class="mt-2" v-if="toc.length">
                <li class="mb-0" :class="'ml-' + ((itm.depth - 2) * 4)" v-for="itm in filteredToc" :key="itm.id">
                    <a :href="itm.href" class="group scrollactive-item block py-1 hover:text-gray-900 text-gray-500"><span class="inline-block max-w-full overflow-wrap-break transition group-hover:translate-r-5px">{{ itm.title }}</span></a>
                </li>
            </ul>
        </div>
    </scrollactive>
</template>

<script>
export default {
    name: 'TableOfContents',
    props: ['title'],
    data() {
        return {
            toc: []
        }
    },
    computed: {
        filteredToc() {
            return this.toc.filter(itm => itm.depth <= 3);
        }
    },
    mounted() {
        this.toc = window.anchors.elements.map(el => {
            return {
                title: el.textContent,
                href: el.querySelector('.anchorjs-link').getAttribute('href'),
                depth: parseInt(el.nodeName.substr(1))
            }
        });
    }
}
</script>