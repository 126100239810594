<template>
    <message-box type="info" heading="Work in Progress">
        <p>More detailed documentation is coming soon.</p>
        <p>If you would like to help write the documentation for this section, you can find details on how to contribute over on our <a :href="gitHubUrl" target="_blank" class="font-medium text-brand-blue-light hover:text-brand-blue-dark hover:underline">GitHub repository</a>.</p>
    </message-box>
</template>

<script>
export default {
    name: 'WorkInProgress',
    computed: {
        gitHubUrl() {
            return window.gitHubUrl;
        }
    }    
}
</script>